import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Computed, Persistence, StateRepository } from '@angular-ru/ngxs/decorators';
import { State } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import {
  CountriesUpdateInterface,
  CountryInterface,
  CountryPaginateInterface,
  MyGamesInterface,
  MyGamesInterfacePaginate,
  Role,
  UserChangeNumber,
  UserChangeNumberResponse,
  UserInterface,
  UserStateInterface,
} from './current-user.interfaces';
import { LogoutService } from '../services/logout.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  NewBingoInformationInterface,
  QuestionsBingoInterface,
} from '../games/add-bingo/add-bingo.interfaces';
import { append, insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Links } from '@shared/interfaces/paginate.interface';
import { NameIdInterface } from '@shared/interfaces/name-id.interface';

@Persistence({
  // path: 'user.accessToken',
  existingEngine: localStorage,
  ttl: 1000 * 60 * 2, // 15 // 15min
})
@StateRepository()
// @State<UserStateInterface>({
@State({
  name: 'eng_current_user',
  defaults: {
    userCountries: [],
    gamesSearch: '',
    isDesktop: false,
    gameFilter: {
      // isEmpty: true,
      age: [],
      language: [],
      category: [],
    },
    isChangedGamesFilter: false,
    isChangedUserPersonalData: false,
  },
})
@Injectable({
  providedIn: 'any',
})
@UntilDestroy()
export class CurrentUserState extends NgxsDataRepository<UserStateInterface> {
  constructor(
    private httpClient: HttpClient,
    private logoutService: LogoutService,
  ) {
    super();
    this.logoutService.logout$.pipe(untilDestroyed(this)).subscribe(() => this.reset());
  }

  @Computed()
  get user(): UserInterface {
    return this.snapshot.user;
  }

  @Computed()
  get userCountries(): CountryInterface[] {
    return this.snapshot.userCountries ?? [];
  }

  @Computed()
  get fullName(): string {
    return this.user?.name ?? '';
  }

  @Computed()
  get isDesktop(): boolean {
    return this.snapshot?.isDesktop;
  }

  @Computed()
  get role(): Role {
    return this.snapshot?.user?.role ?? Role.learner;
  }

  @Computed()
  get myGames(): MyGamesInterface[] {
    return this.snapshot?.myGames?.games ?? [];
  }

  @Computed()
  get myFilteredGames(): MyGamesInterface[] {
    return this.snapshot?.myFilteredGames?.games ?? [];
  }

  @Computed()
  get myGamesShown(): MyGamesInterface[] {
    return (
      (this.snapshot.gamesSearch == '' &&
      this.snapshot.gameFilter &&
      this.snapshot.gameFilter.language.length === 0 &&
      this.snapshot.gameFilter.age.length === 0 &&
      this.snapshot.gameFilter.category.length === 0
        ? this.snapshot?.myGames?.games
        : this.snapshot?.myFilteredGames?.games) || []
    );
  }

  @Computed()
  get myGamesShown2D(): MyGamesInterface[][] {
    const array =
      (this.snapshot.gamesSearch == '' &&
      this.snapshot.gameFilter &&
      this.snapshot.gameFilter.language.length === 0 &&
      this.snapshot.gameFilter.age.length === 0 &&
      this.snapshot.gameFilter.category.length === 0
        ? this.snapshot?.myGames?.games
        : this.snapshot?.myFilteredGames?.games) || [];
    return this.get2DArray(array);
  }

  @Computed()
  get myGamesLinks(): Links {
    return this.snapshot?.myGames?.links ?? {};
  }

  @Computed()
  get myFilteredGamesLinks(): Links {
    return this.snapshot?.myFilteredGames?.links ?? {};
  }

  @Computed()
  get publicGames(): MyGamesInterface[] {
    return this.snapshot?.publicGames?.games ?? [];
  }

  @Computed()
  get publicFilteredGames(): MyGamesInterface[] {
    return this.snapshot?.publicFilteredGames?.games ?? [];
  }

  @Computed()
  get publicGamesShown(): MyGamesInterface[] {
    return (
      (this.snapshot.gamesSearch == ''
        ? this.snapshot?.publicGames?.games
        : this.snapshot?.publicFilteredGames?.games) || []
    );
  }

  @Computed()
  get publicGamesShown2D(): MyGamesInterface[][] {
    const array =
      (this.snapshot.gamesSearch == '' &&
      this.snapshot.gameFilter &&
      this.snapshot.gameFilter.language.length === 0 &&
      this.snapshot.gameFilter.age.length === 0 &&
      this.snapshot.gameFilter.category.length === 0
        ? this.snapshot?.publicGames?.games
        : this.snapshot?.publicFilteredGames?.games) || [];
    return this.get2DArray(array);
  }

  get2DArray(array: MyGamesInterface[]): MyGamesInterface[][] {
    const resultArray: MyGamesInterface[][] = [];
    const columns = this.snapshot.isDesktop ? 3 : 1;
    for (let i = 0; i < array.length; i += columns) {
      resultArray.push(array.slice(i, i + columns));
    }
    return resultArray;
  }

  @Computed()
  get publicGamesLinks(): Links {
    return this.snapshot?.publicGames?.links ?? {};
  }
  @Computed()
  get publicFilteredGamesLinks(): Links {
    return this.snapshot?.publicFilteredGames?.links ?? {};
  }

  @Computed()
  get isAdmin(): boolean {
    return this.snapshot?.user?.role === Role.admin;
  }

  @Computed()
  get isSuperAdmin(): boolean {
    return this.snapshot?.user?.role === Role.superAdmin;
  }

  @Computed()
  get canPublish(): boolean {
    return (
      this.snapshot?.user?.role === Role.admin ||
      this.snapshot?.user?.role === Role.teacher ||
      this.snapshot?.user?.role === Role.superAdmin
    );
  }

  @Computed()
  get isChangedUserPersonalData(): boolean {
    return this.snapshot.isChangedUserPersonalData ?? false;
  }

  cleanFilters() {
    this.ctx.setState((state) => ({
      ...state,
      gameFilter: { language: [], age: [], category: [] },
      gamesSearch: '',
    }));
  }

  // @DataAction()
  myGame(id: number): MyGamesInterface | undefined {
    return (this.snapshot?.myGames?.games ?? []).find((myGame) => myGame.id === id);
  }

  // @DataAction()
  publicGame(id: number): MyGamesInterface | undefined {
    return (this.snapshot?.publicGames?.games ?? []).find((myGame) => myGame.id === id);
  }

  // @DataAction()
  show(): Observable<UserInterface> {
    return (
      this.httpClient
        .get<UserInterface>('{api}/users/show')
        // .pipe(tap((user) => this.ctx.setState((state) => ({ ...state, user }))));
        .pipe(
          tap((user) =>
            this.ctx.setState((state) => {
              return { ...state, user };
            }),
          ),
        )
    );
  }

  // @DataAction()
  update(params: UserInterface): Observable<UserInterface> {
    params.country = undefined;
    params.agree = undefined;
    return this.httpClient.post<UserInterface>('{api}/users/update', params).pipe(
      tap(() => {
        this.ctx.setState((state) => ({
          ...state,
          user: { ...state.user, name: params.name, email: params.email },
        }));
      }),
    );
  }

  // @DataAction()
  updatePhone(params: UserChangeNumber): Observable<UserChangeNumberResponse> {
    params.new_phone = params.new_phone.replace('+', '');
    return this.httpClient.post<UserChangeNumberResponse>('{api}/users/update-phone', params);
  }

  // @DataAction()
  countries(next?: string | null | undefined): Observable<CountryPaginateInterface> {
    const url = next ?? `{api}/users/countries`;
    return this.httpClient.get<CountryPaginateInterface>(url);
  }

  // @DataAction()
  userCountries$(): Observable<CountryInterface[]> {
    return this.httpClient
      .get<CountryInterface[]>(`{api}/users/countries/show`)
      .pipe(tap((userCountries) => this.ctx.setState((state) => ({ ...state, userCountries }))));
  }

  // @DataAction()
  updateCountries(params: CountriesUpdateInterface, country?: NameIdInterface[]): Observable<any> {
    if (!params.countries.length) {
      return of();
    }
    return this.httpClient.post<UserInterface>('{api}/users/countries/user_update', params).pipe(
      tap(() => {
        if (!country) {
          return;
        }
        this.ctx.setState((state) => ({
          ...state,
          user: { ...state.user, country },
          userCountries: country,
        }));
      }),
    );
  }

  updateSearchString(s: string) {
    this.ctx.setState((state) => ({ ...state, gamesSearch: s }));
  }

  updateIsDesktop(isDesktop: boolean) {
    this.ctx.setState((state) => ({ ...state, isDesktop }));
  }

  // @DataAction()
  myGames$(next?: string | null | undefined, s: string = ''): Observable<MyGamesInterfacePaginate> {
    const url = next ?? `{api}/games`;
    return this.httpClient.get<MyGamesInterfacePaginate>(url).pipe(
      tap((myGames) => {
        if (next && this.snapshot?.myGames?.games) {
          this.ctx.setState(
            patch({
              myGames: patch({
                games: append<MyGamesInterface>(myGames.data ?? []),
                links: myGames.links,
              }),
              gamesSearch: s,
            }),
          );
          return;
        }
        this.ctx.setState((state) => ({
          ...state,
          myGames: {
            games: myGames.data ?? [],
            links: myGames.links,
          },
          gamesSearch: s,
        }));
      }),
    );
  }

  // @DataAction()
  myFilteredGames$(
    next?: string | null | undefined,
    s: string = '',
    language: number[] = [],
    age: number[] = [],
    category: number[] = [],
  ): Observable<MyGamesInterfacePaginate> {
    if (
      this.myGames.length &&
      !this.myGamesLinks?.next &&
      language.length === 0 &&
      age.length === 0 &&
      category.length === 0
    ) {
      const myFilteredGames = this.myGames.filter((value) => {
        return value.title.toLocaleLowerCase().includes(s.toLocaleLowerCase());
      });
      this.ctx.setState((state) => ({
        ...state,
        myFilteredGames: {
          games: myFilteredGames ?? [],
          gameFilterInterface: { language, age, category },
          // TODO fix links
          // links: myFilteredGames.links,
        },
        gamesSearch: s,
      }));
      return of(this.ctx.getState().myFilteredGames);
    }
    let params = new HttpParams();
    if (s?.length > 0) {
      params = params.set('search', s);
    }
    if (language.length > 0) {
      params = params.set('language', language.join(','));
    }
    if (age.length > 0) {
      params = params.set('age', age.join(','));
    }
    if (category.length > 0) {
      params = params.set('category', category.join(','));
    }
    const url = next ?? `{api}/games`;
    return this.httpClient
      .get<MyGamesInterfacePaginate>(url, {
        params: params,
      })
      .pipe(
        tap((myFilteredGames) => {
          if (next && this.snapshot?.myFilteredGames?.games) {
            this.ctx.setState(
              patch({
                myFilteredGames: patch({
                  games: append<MyGamesInterface>(myFilteredGames.data ?? []),
                  links: myFilteredGames.links,
                }),
                gamesSearch: s,
                gameFilter: { language, age, category },
              }),
            );
            return;
          }
          this.ctx.setState((state) => ({
            ...state,
            myFilteredGames: {
              games: myFilteredGames.data ?? [],
              links: myFilteredGames.links,
            },
            gamesSearch: s,
            gameFilter: { language, age, category },
          }));
        }),
      );
  }

  // @DataAction()
  myGame$(id: number): Observable<MyGamesInterface> {
    return this.httpClient.get<MyGamesInterface>(`{api}/games/${id}`).pipe(
      tap((myGame) => {
        if (!this.snapshot?.myGames?.games) {
          this.ctx.setState((state) => ({ ...state, myGames: { games: [myGame] } }));
          return;
        }
        if (this.myGame(id)) {
          this.ctx.setState(
            patch({
              myGames: patch({
                games: updateItem<MyGamesInterface>((_myGame) => _myGame?.id === id, myGame),
              }),
            }),
          );
          return;
        }
        this.ctx.setState(
          patch({
            myGames: patch({
              games: insertItem<MyGamesInterface>(myGame),
            }),
          }),
        );
      }),
    );
  }

  // @DataAction()
  myGamesQuestions$(games: MyGamesInterface[]): Observable<any[]> {
    const question: any[] = [];
    games.forEach((game) =>
      question.push(
        this.httpClient.get<QuestionsBingoInterface[]>(`{api}/games/${game.id}/questions`),
      ),
    );

    // TODO remove forkJoin
    return (forkJoin(question) as Observable<QuestionsBingoInterface[][]>).pipe(
      tap((questions) => {
        const myGames = this.snapshot.myGames;
        games.forEach((game, index) => {
          const foundMyGame = myGames.games.find((myGame) => myGame.id === game.id);
          if (foundMyGame) {
            this.ctx.setState(
              patch({
                myGames: patch({
                  games: updateItem<MyGamesInterface>(
                    (item) => item?.id === foundMyGame.id,
                    patch({ questions: questions[index] }),
                  ),
                }),
              }),
            );
          }
        });
      }),
    );
  }

  // @DataAction()
  publicGames$(next?: string | null | undefined): Observable<MyGamesInterfacePaginate> {
    const url = next ?? `{api}/games/public-favourites`;
    return this.httpClient.get<MyGamesInterfacePaginate>(url).pipe(
      tap((myGames) => {
        if (next && this.snapshot?.publicGames?.games) {
          this.ctx.setState(
            patch({
              publicGames: patch({
                games: append<MyGamesInterface>(myGames.data ?? []),
                links: myGames.links,
              }),
            }),
          );
          return;
        }
        this.ctx.setState((state) => ({
          ...state,
          publicGames: {
            games: myGames.data ?? [],
            links: myGames.links,
          },
        }));
      }),
    );
  }

  publicFilteredGames$(
    next?: string | null | undefined,
    s: string = '',
    language: number[] = [],
    age: number[] = [],
    category: number[] = [],
  ): Observable<MyGamesInterfacePaginate> {
    if (
      this.publicGames.length &&
      !this.publicGamesLinks?.next &&
      language.length === 0 &&
      age.length === 0 &&
      category.length === 0
    ) {
      const publicFilteredGames = this.publicGames.filter((value) => {
        return value.title.toLocaleLowerCase().includes(s.toLocaleLowerCase());
      });
      this.ctx.setState((state) => ({
        ...state,
        publicFilteredGames: {
          games: publicFilteredGames ?? [],
          gameFilterInterface: { language, age, category },
          // TODO fix links
          // links: publicFilteredGames.links,
        },
        gamesSearch: s,
      }));
      return of(this.ctx.getState().publicFilteredGames);
    }
    let params = new HttpParams();
    if (s?.length > 0) {
      params = params.set('search', s);
    }
    if (language.length > 0) {
      params = params.set('language', language.join(','));
    }
    if (age.length > 0) {
      params = params.set('age', age.join(','));
    }
    if (category.length > 0) {
      params = params.set('category', category.join(','));
    }
    const url = next ?? `{api}/games/public-favourites`;
    return this.httpClient.get<MyGamesInterfacePaginate>(url, { params }).pipe(
      tap((myGames) => {
        if (next && this.snapshot?.publicGames?.games) {
          this.ctx.setState(
            patch({
              publicFilteredGames: patch({
                games: append<MyGamesInterface>(myGames.data ?? []),
                links: myGames.links,
              }),
              gamesSearch: s,
              gameFilter: { language, age, category },
            }),
          );
          return;
        }
        this.ctx.setState((state) => ({
          ...state,
          publicFilteredGames: {
            games: myGames.data ?? [],
            links: myGames.links,
          },
          gamesSearch: s,
          gameFilter: { language, age, category },
        }));
      }),
    );
  }

  // @DataAction()
  resetGames() {
    this.ctx.setState((state) => ({ ...state, myGames: { games: [] } }));
  }

  resetMyFilteredGames() {
    this.ctx.setState((state) => ({ ...state, myFilteredGames: { games: [] } }));
  }

  // @DataAction()
  resetPublicGames() {
    this.ctx.setState((state) => ({ ...state, publicGames: { games: [] } }));
  }
  resetPublicFilteredGames() {
    this.ctx.setState((state) => ({ ...state, publicFilteredGames: { games: [] } }));
  }

  // @DataAction()
  deleteGame(gameID: number): Observable<any> {
    return this.httpClient.delete<any>(`{api}/games/${gameID}`).pipe(
      tap(() => {
        this.ctx.setState(
          patch({
            myGames: patch({
              games: removeItem<MyGamesInterface>((item) => item?.id === gameID),
            }),
          }),
        );
      }),
    );
  }

  // @DataAction()
  stopGame(gameID: number): Observable<any> {
    return this.httpClient.post<any>(`{api}/gameplay/teacher/cancel`, {}).pipe(
      tap(() => {
        this.ctx.setState(
          patch({
            myGames: patch({
              games: updateItem<MyGamesInterface>(
                (item) => item?.id === gameID,
                patch({
                  status: 0,
                }),
              ),
            }),
          }),
        );
      }),
    );
  }

  // @DataAction()
  duplicate$(id: number): Observable<MyGamesInterface> {
    return this.httpClient.post<MyGamesInterface>(`{api}/games/${id}/duplicate`, {}).pipe(
      tap((myGame) => {
        if (!this.snapshot?.myGames?.games) {
          this.ctx.setState((state) => ({ ...state, myGames: { games: [myGame] } }));
          return;
        }
        this.ctx.setState(
          patch({
            myGames: patch({
              games: insertItem<MyGamesInterface>(myGame),
            }),
          }),
        );
      }),
    );
  }

  // @DataAction()
  duplicateGameByCode(id: number | string): Observable<MyGamesInterface> {
    return this.httpClient.post<MyGamesInterface>(`{api}/games/${id}/copy`, {}).pipe(
      tap((myGame) => {
        if (!this.snapshot?.myGames?.games) {
          this.ctx.setState((state) => ({ ...state, myGames: { games: [myGame] } }));
          return;
        }
        this.ctx.setState(
          patch({
            myGames: patch({
              games: insertItem<MyGamesInterface>(myGame),
            }),
          }),
        );
      }),
    );
  }

  // @DataAction()
  share$(id: number): Observable<MyGamesInterface> {
    return this.httpClient.post<MyGamesInterface>(`{api}/games/${id}/generate-share-link`, {}).pipe(
      tap((game) => {
        if (this.myGame(id)) {
          this.ctx.setState(
            patch({
              myGames: patch({
                games: updateItem<MyGamesInterface>((_myGame) => _myGame?.id === id, game),
              }),
            }),
          );
          return;
        }
      }),
    );
  }

  // @DataAction()
  setFlagChangedPersonalData(isChangedUserPersonalData: boolean) {
    this.ctx.setState((state) => ({ ...state, isChangedUserPersonalData }));
  }

  // @DataAction()
  setPublicGameStatus(id: number, game: NewBingoInformationInterface, public_favourite: boolean) {
    return this.httpClient
      .post<MyGamesInterface>(`{api}/games/${id}/update`, { public_favourite })
      .pipe(
        tap(() => {
          if (this.myGame(id)) {
            this.ctx.setState(
              patch({
                myGames: patch({
                  games: updateItem<MyGamesInterface>((_myGame) => _myGame?.id === id, {
                    ...game,
                    public_favourite,
                  }),
                }),
              }),
            );
          }
          if (!public_favourite && this.publicGame(id)) {
            this.ctx.setState(
              patch({
                publicGames: patch({
                  games: removeItem<MyGamesInterface>((item) => item?.id === id),
                }),
              }),
            );
          }
        }),
      );
  }
}
